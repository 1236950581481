import { rem } from 'polished';
import styled from 'styled-components';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: ${rem(10)};
    margin-top: ${rem(60)};
    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${breakpoints.sm}px) {
        margin-top: ${rem(DESKTOP_HEADER_HEIGHT)};
    }

    @media (min-width: ${breakpoints.lg}px) {
        padding: ${rem(10)} 0;
    }
`;

export const Section = styled.div`
    background: ${({ theme }) => theme.colors.greyMedium};
    height: ${rem(200)};
    padding: ${rem(30)};
    border-radius: ${rem(10)};
    position: relative;
    display: flex;
    width: calc(50% - ${rem(10)});
    margin-top: ${rem(10)};
    align-items: flex-end;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    cursor: pointer;

    @media (min-width: ${breakpoints.sm}px) {
        width: calc(25% - ${rem(10)});
        height: ${rem(250)};
    }

    &:not(:last-child) {
        margin-right: ${rem(10)};
    }

    span {
        word-spacing: ${rem(9999)};
    }

    svg {
        width: ${rem(60)};
        height: ${rem(60)};
        position: absolute;
        left: ${rem(30)};
        top: ${rem(30)};
        padding: ${rem(17)};
        background: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
    }

    &:hover,
    &:focus-visible {
        background: ${({ theme }) => theme.colors.border};
    }
`;
