import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { publicAssets } from '@utils/url.utils';
import { ROUTES, getImageBaseURL } from '../../constants';
import {
    About,
    Benefit,
    BenefitLabel,
    BenefitNumber,
    Benefits,
    Description,
    Player,
    Title,
    Top,
    Video,
    Wrapper,
} from './Hero.elements';

export interface HeroProps {}

const HeroTemplate: FC<HeroProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    useEffect(() => {
        const video = document.querySelector('video');

        function handleIntersection(entries: any) {
            entries.map(async (entry: any) => {
                if (entry.isIntersecting) {
                    const res = await fetch(`${getImageBaseURL()}/media/static/profi_promo_nomusic_ver5.mp4`);
                    const data = await res.blob();
                    video.src = URL.createObjectURL(data);
                }
            });
        }

        const observer = new IntersectionObserver(handleIntersection);
        observer.observe(video);
    }, []);

    return (
        <Wrapper>
            <Top>
                <Video>
                    <Player loop autoPlay muted />
                </Video>
                <About>
                    <Title dangerouslySetInnerHTML={{ __html: t('homepage.hero.title') }} />
                    <Description>{t('homepage.hero.description')}</Description>
                    <ButtonTemplate
                        onClick={() => router.push({ pathname: ROUTES.TRAININGS })}
                        text={t('homepage.hero.button.primary')}
                        marginBottom={15}
                    />
                </About>
            </Top>
            <Benefits>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit1.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit1.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit2.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit2.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit3.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit3.label') }} />
                </Benefit>
                <Benefit>
                    <BenefitNumber>{t('homepage.benefit4.number')}</BenefitNumber>
                    <BenefitLabel dangerouslySetInnerHTML={{ __html: t('homepage.benefit4.label') }} />
                </Benefit>
            </Benefits>
        </Wrapper>
    );
};

export default HeroTemplate;
